// Set site-wide default values
$font-stack: "iA Writer Duospace", monospace;
$base_font_size: 20px;
$base-font-weight: normal;
$base-font-color: black;
$base_line_height: 1.8rem;
$base-background-color: #fff;
$side-margins: 1rem;
$max-column-width: 760px;
$breakpoint: 800px;

// import styles
@import 'normalize';
@import 'fonts';
// all custom styles are set in styles.scss
@import 'styles';

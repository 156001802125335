
// style standard html elements
*{
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

html {
  font: {
    family: $font-stack;
    size: $base_font_size;
    weight: $base-font-weight;
  }
  color: $base-font-color;
  line-height: $base_line_height;
  background-color: $base-background-color;
}

a {
  color: #169BD5;
  text-decoration: none;
}

nav {
  clear: both;
  ul {
    padding: 0;
    margin: 0;
  }
  ul li {
    list-style: none;
    width: 100%;
    text-align: center;
    line-height: 1.8rem;
  }
}

main {
  padding: 0 $side-margins 3rem;
  margin-top: 3rem;
}

h1 {
  font-size: 1.6rem;
  line-height: 1.8rem;
}

img {
  width: 100%;
}

footer {
  color: darkgray;
  font-size: 0.8rem;
  margin-bottom: 0;
}

// classes

// hide stuff that is only visible on bigger screens
.tablet {
  display: none;
}

//other classes
.column_width {
  max-width: $max-column-width;
  margin: 0 auto;
}

.header {
  margin: 2rem 1rem 3rem 1rem;
  overflow: auto;
}

.gallery-logo {
  max-width: 50%;
  float: left;
}

.language-select {
  float: right;
}

.exhibition_index_card {
  margin-bottom: 6rem;
}

.card {
  margin-top: 0;
  margin-bottom: 6rem;
}

.card_title {
  font-weight: bold;
}

.uptimerobot-logo {
  max-width: 100px;
}

// responsive stuff we change on bigger screens

@media (min-width: $breakpoint ) {
  .tablet {
    display: inline-block;
  }
  nav {
    ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  li:first-child {
    align-self: flex-start;
  }
  }
  .header {
    margin: 2rem auto 3rem auto;
  }
}
